<template>
	<header ref="header" class="the-header" v-scroll="handleScroll"
			:class="headerClass">
		<v-container class="py-3 py-md-7 py-lg-5">

			<transition name="burger" appear>
				<burger-menu v-if="withBurgerMenu" class="z-index-2"/>
			</transition>

			<transition name="basket" appear>
				<basket-popup v-if="withBasketPopup" class="z-index-1"/>
			</transition>

			<v-row class="d-md-none align-center px-4 z-index-3 relative the-header__mobile">

				<v-col cols="4">
					<button @click="onToggleBurgerMenu" class="the-header__hamburger-btn d-flex flex-column"
							:class="{'the-header__close-btn' : withBurgerMenu}">
						<span></span>
						<span></span>
						<span></span>
					</button>
				</v-col>

				<v-col cols="4">

					<router-link :to="{name: 'home'}">
						<svgicon :name="isLogoWhite?'logo':'logo-dark'" class="the-header__logo"/>
					</router-link>

				</v-col>

				<v-col cols="4" class="d-flex align-center justify-end">

					<div class="the-header__langs">
						<span class="the-header__langs-current item"
							  @click="isLangOn=!isLangOn">{{currentLangCode}}</span>
						<div class="the-header__langs-selector" :class="{visible:isLangOn}">
							<div class="the-header__langs-items">
								<template v-for="(item, index) of langs">
									<span :class="{active:item.code===currentLangCode}"
										  @click="onLang(item)">{{item.name}}
									</span>
									<span v-if="index<langs.length-1" class="dot"></span>
								</template>
							</div>
						</div>
					</div>

					<button v-if="isSaleOn" class="ml-5 the-header__basket-btn"
							:class="{'with-products':countBasketProducts}">

						<transition name="fade-quick" mode="out-in">
							<div v-if="withBasketPopup" class="the-header__close-btn">
								<span></span>
								<span></span>
								<span></span>
							</div>
							<div v-else>
								<router-link :to="{name: 'basket'}">
									<svgicon name="shop"/>
								</router-link>
								<router-link v-if="countBasketProducts" :to="{name:'basket'}"
											 class="basket-amount">{{countBasketProducts}}</router-link>
							</div>
						</transition>

					</button>

				</v-col>
			</v-row>

			<div class="d-none d-md-flex justify-space-between align-center the-header__desktop">
				<router-link :to="{name: 'home'}">
					<svgicon :name="isLogoWhite?'logo':'logo-dark'" class="the-header__logo "/>
				</router-link>

				<div class="d-flex justify-space-between align-center">
					<template v-for="(item,index) of items">
						<!--<a v-if="item.scrollTo" href="#" @click.prevent="scrollTo(item)" class="item">{{item.name}}</a>-->
						<router-link :to="item.to" class="item">{{ item.name }}</router-link>
					</template>
				</div>
				<div class="d-flex align-center justify-space-between">

					<div class="the-header__langs">
						<span class="the-header__langs-current item"
							  @click="isLangOn=!isLangOn">{{currentLangCode}}</span>
						<div class="the-header__langs-selector" :class="{visible:isLangOn}">
							<div class="the-header__langs-items">
								<template v-for="(item, index) of langs">
								<span :class="{active:item.code===currentLangCode}"
									  @click="onLang(item)">{{item.name}}
								</span>
									<span v-if="index<langs.length-1" class="dot"></span>
								</template>
							</div>
						</div>
					</div>

					<button v-if="isSaleOn" class="ml-5 ml-lg-7 the-header__basket-btn"
							:class="{'with-products':countBasketProducts}">
						<router-link :to="{name: 'basket'}">
							<svgicon name="shop"/>
						</router-link>

						<router-link v-if="countBasketProducts" :to="{name:'basket'}"
									 class="basket-amount">{{countBasketProducts}}</router-link>
					</button>
				</div>
			</div>

		</v-container>
	</header>
</template>

<script>
	import BurgerMenu from './burger-menu.vue';
	import BasketPopup from "@/views/Basket/basket-popup";
	import {scrollTo} from '@/utils/utils';
	import config from '@/config';

	export default {
		name: "the-header",
		components: {
			BurgerMenu,
			BasketPopup
		},
		data() {
			return {
				isHeaderScrolled: false,
				isHeaderBurgered: false,
				isLangOn: false
			}
		},
		computed: {
			isSaleOn(){
				return config.IS_SALE_ON
			},
			langs() {
				return config.LANGUAGES;
			},
			currentLangCode() {
				let lang = config.LANGUAGES.find(el => el.code === this.common.languageCode);
				if (!lang) lang = config.LANGUAGES[0];
				return lang.code;
			},
			items() {
				return [
					{name: this.common?.texts?.COMMON_MENU_1.NAME, to: {name: "home-shop"}},
					{name: this.common?.texts?.COMMON_MENU_2.NAME, to: {name: "home-flavours"}},
					{name: this.common?.texts?.COMMON_MENU_3.NAME, to: {name: "home-story"}},
					{name: this.common?.texts?.COMMON_MENU_4.NAME, to: {name: "home-corporate"}},
					{name: this.common?.texts?.COMMON_MENU_5.NAME, to: {name: "home-partnership"}},
					//{name: "Souvenirs", to: {name:"category", params: {code:"souvenirs"}}},
					//{name: "Special occasion", to: {name:"home-special"}},
					//{name: "Our stores", to: {name:"home-stores"}},
					{name: this.common?.texts?.COMMON_MENU_6.NAME, to: {name: "home-stores"}},
					//{name: "Terms of sale", to: {name:"terms"}},
				]
			},
			common() {
				return this.$store.state.common;
			},
			headerClass() {
				return {
					'inversed': (
						this.isHeaderInversed
						|| this.isRouteHeaderInversed),
					'scrolled': this.isHeaderScrolled,
					'burgered': this.isHeaderBurgered,
				}
			},
			withBurgerMenu() {
				return this.$store.state.withBurgerMenu;
			},
			isLogoWhite() {
				// стейт может форсировать инверсивный хедер
				return this.$store.state.isHeaderInversed && !this.isHeaderBurgered;
			},
			isHeaderInversed() {
				// стейт может форсировать инверсивный хедер
				return this.$store.state.isHeaderInversed;
			},
			isRouteHeaderInversed() {
				// роут может форсировать инверсивный хедер
				return this.$route.meta.isHeaderInversed;
			},
			headerHeight() {
				return this.$store.state.headerHeight;
			},
			basket() {
				return this.$store.state.session.basket;
			},
			countBasketProducts() {
				return this.basket?.reduce((total, el) => total + el.AMOUNT, 0);
			},
			withBasketPopup() {
				return this.$store.state.withBasketPopup;
			}
		},
		methods: {
			onLang(lang) {
				window.location.href = lang.host + this.$route.path;
			},
			onResize() {
				this.$store.state.headerHeight = this.$refs.header.clientHeight;
			},
			scrollTo(item) {
				// todo set anchor
				//scrollTo(item.scrollTo, -this.$store.state.headerHeight);
				this.$router.push({name: item.name})
			},
			onToggleBasket() {
				this.$store.state.withBurgerMenu = false;
				this.$store.state.withBasketPopup = !this.$store.state.withBasketPopup;
				if (this.$store.state.withBasketPopup) setTimeout(() => {
					this.isHeaderBurgered = true;
				}, 100);
				else this.isHeaderBurgered = false;
			},
			onToggleBurgerMenu() {
				this.$store.state.withBasketPopup = false;
				this.$store.state.withBurgerMenu = !this.$store.state.withBurgerMenu;
				if (this.$store.state.withBurgerMenu) setTimeout(() => {
					this.isHeaderBurgered = true;
				}, 100);
				else this.isHeaderBurgered = false;
			},
			handleScroll() {

				this.$store.state.headerHeight = this.$refs.header.clientHeight;
				if (this.withBurgerMenu) return;
				if (this.isHeaderClassWhite) return;

				if (window.scrollY > 100) this.isHeaderScrolled = true;
				else this.isHeaderScrolled = false;
				/*if (window.scrollY > this.headerHeight && window.scrollY < (this.$store.state.introHeight - this.headerHeight)) {
					this.isHeaderScrolled = true;
				} else {
					this.isHeaderScrolled = false;
				}*/

				/*this.isHeaderWhite = false;
				const y = window.scrollY;
				this.$store.state.whiteHeaderSections.forEach(el=>{
					if (y >= el.height - this.headerHeight && y < el.y )) {
						this.isHeaderWhite = true;
					}
		  })*/

			}
		},
		mounted() {
			window.addEventListener("resize", this.onResize);
			this.onResize();
		},
		destroyed() {
			window.removeEventListener("resize", this.onResize);
		}
	}
</script>

<style lang="scss">
	.the-header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: $z-highest;
		@include transition();
		//background: transparent;
		background: $header-white; //$white;


		/*&.scrolled {
		  background: rgba(95, 95, 95, 0.25);//$white;
		}*/

		&.inversed {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));

			&.scrolled {
				background: rgba(95, 95, 95, 0.25);
			}

			&:not(.burgered) {
				.the-header {
					&__logo {
						fill: $white;
					}

					&__basket-btn {
						svg {
							fill: $white;
						}
					}

					&__hamburger-btn {
						span {
							background: $white;
						}
					}

					&__desktop, &__mobile {
						.item {
							color: $white !important;
							//font-weight: $fw-book;

							&:hover {
								//text-decoration-color: $white;
								border-bottom: 1px solid $white;
							}
						}

						.the-header__langs {
							&-selector {
								background: transparent;
							}

							&-items {
								span {
									color: $white;

									&.dot {
										background-color: $golden;
									}
								}
							}
						}
					}
				}
			}
		}

		&__logo {
			margin: auto auto;
			display: block;
			width: 52px;
			height: 52px;
			fill: $primary-500;

			@include up($md) {
				width: 57px;
				height: 57px;
			}
			@include up($lg) {
				width: 83px;
				height: 83px;
			}
		}

		&__basket-btn {
			width: 24px;
			position: relative;
			top: -1px;

			@include up($md) {
				top: -2px;
				width: 20px;
			}
			@include up($lg) {
				width: 24px;
			}

			svg {
				width: 100%;
				//height: 30px;
			}

			&.with-products {
				svg {
					fill: $black;
				}
			}

			.basket-amount {
				top: -4px;
				right: -4px;
			}
		}

		&__hamburger-btn {
			justify-content: space-between;
			height: 50px;
			padding: 15px 0 15.5px 0;
			border: 0;

			span {
				display: block;
				width: 30px;
				height: 1.5px;
				background: $black;
				@include transition();
			}
		}

		.item {
			display: inline-block;
			margin: 0 6px;
			padding: 0 2px;
			font-family: $header-font-family;
			font-size: $fs-h6;
			letter-spacing: $ls-h6;
			//color: $black !important;
			white-space: nowrap;
			border-bottom: 1px solid transparent;
			cursor: pointer;

			&:not(.router-link-active) {
				color: $black !important;
			}

			&:hover {
				//opacity: 0.6;
				//text-decoration-color: $primary-500;
				border-bottom: 1px solid $black-50;
			}

			&.router-link-active {
				border-bottom: 1px solid $golden;
			}

			/*&:first-child {
			  //height: 50px;
			  //line-height: 46px;
			  border: 2px solid $primary-500;
			  padding: 4px 16px;
			  margin-right: 16px;
			  @include transition();

			  &:hover, &:active {
				background: $primary-500;
				color: $white !important;
			  }
			}*/

			@include up($lg) {
				margin: 0 8px;
				padding: 0 3px;
				font-size: $fs-h5;
				letter-spacing: $ls-h5;
			}
		}

		// for both burger btn and basket btn
		&__close-btn {
			position: relative;
			padding: 0;
			height: 50px;
			width: 30px;

			.the-header__basket-btn & {
				left: -3px;
			}

			span {
				display: block;
				width: 30px;
				height: 1.5px;
				background: $black;
				@include transition();

				&:nth-child(1) {
					position: absolute;
					top: 50%;
					transform: rotate(45deg) translateY(-50%);
				}

				&:nth-child(3) {
					display: none;
				}

				&:nth-child(2) {
					position: absolute;
					top: 50%;
					transform: rotate(-45deg) translateY(-50%);
				}
			}
		}

		&__langs {
			position: relative;

			&-current {
				position: relative;
				z-index: 1;

				&:hover {
					border-bottom-color: transparent !important;
				}
			}

			&-selector {
				display: none;
				position: fixed;
				right: 0;
				top: 76px;
				width: 100%;
				justify-content: center;
				background: $header-white;

				@include up($sm) {
					position: absolute;
					width: auto;
					top: 0;
					right: 40px;
					background: unset;
				}

				@include up($md) {
					top: 30px;
					right: 0;
				}

				&.visible {
					display: flex;
				}
			}

			&-items {
				padding: 4px 8px 4px 8px;
				//background: $white;
				border-top: 1px solid $primary-50;
				//border-right: 1px solid $primary-50;
				display: flex;
				align-items: center;

				@include up($sm) {
					padding: 0;
					border-top: none;
				}

				@include up($md) {
					padding: 4px 8px 4px 8px;
					border-top: 1px solid $primary-50;
				}

				span {
					text-align: right;
					cursor: pointer;
					color: $black;
					@include transition();

					&.active {
						color: $default-text-color;
					}

					&:hover {
						opacity: 0.6;
					}
				}
			}

			/*&:hover {
				.the-header__langs-selector {
					display: flex;
				}
			}*/
		}
	}

	.burger-enter-active, .burger-leave-active {
		transition: transform .2s
	}

	.burger-enter, .burger-leave-to {
		transform: translateX(-100%)
	}

	.basket-enter-active, .basket-leave-active {
		transition: transform .2s
	}

	.basket-enter, .basket-leave-to {
		transform: translateX(100%)
	}

</style>