<template>
	<div class="layout-main" :class="{loading:isLoading}">
		<the-header/>
		<v-main>
			<!--<transition name="route" mode="out-in">-->
				<router-view />
			<!--</transition>-->
		</v-main>
		<the-footer />
	</div>
</template>

<script>
    import TheHeader from './the-header';
    import TheFooter from './the-footer';
    //import BasketPopup from "@/views/basket/basket-popup";

    export default {
        name: "layout-main",
        components: {
            //BasketPopup,
            TheHeader,
            TheFooter
        },
		computed: {
			isLoading() {
				return this.$store.state.isLoading;
			}
		}
    }
</script>

<style lang="scss">
	.layout-main {
		main {
			@include transition-long();
		}

		&.loading {
			main {
				//filter: blur(8px);
				//transform: translateY(4px);
				opacity: 0;
				//opacity: 0.25;
			}
		}
	}
</style>